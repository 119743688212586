var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import Button from "../ui/button";
import FormField from "../ui/form-field";
import Checkbox from "../ui/checkbox";
import { connect } from "formik";
import PreventTransitionPrompt from '../ui/prevent-transition-prompt';
import { DISCLAIMER_FILENAME, DISCLAIMER_MIMETYPE, MIN_FILE_SIZE_BYTE } from '../constants';
import * as React from 'react';
import axios from 'axios';
var styles = require('./disclaimer-form.scss');
var DisclaimerForm = /** @class */ (function (_super) {
    __extends(DisclaimerForm, _super);
    function DisclaimerForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            disclaimerTemplateName: '',
            acknowledged: false,
            disclaimerExists: false
        };
        _this.hasErrors = function () {
            return !!Object.keys(_this.props.formik.errors).length;
        };
        _this.disableSubmit = function () {
            return !_this.state.acknowledged || _this.hasErrors() || _this.props.formik.isSubmitting;
        };
        _this.confirmDateValidation = function (value) {
            var input = value;
            var error, equal;
            if (typeof input === "undefined" || input === '') {
                error = 'Required';
            }
            else {
                var inputDate = new Date(input.replace(/-/g, '\/'));
                var today = new Date();
                equal = inputDate.toDateString() === today.toDateString();
                error = equal ? undefined : 'Must match date submitting';
            }
            return error;
        };
        _this.disclaimerTemplate = function (disclaimerTemplateName) {
            var templates = {
                default: [
                    'I am submitting to Mazda Motor Corporation ("Mazda") a claim for reimbursement for repair or replacement of the part containing the safety or noncompliance defect performed to date. The vehicle identification number (VIN) is:',
                    _this.props.vin,
                    'In exchange for Mazda\'s payment of that claim, I hereby release Mazda, its agents, and its related entities from all claims for such inspection/repair costs. This release shall benefit Mazda and its authorized agent Mazda Motor of America, Inc. dba Mazda North American Operations, its regions/distributors (foreign and domestic), its authorized dealerships, and all their respective directors, officers, agents, employees, divisions, subsidiaries, and affiliated companies. This release shall bind my heirs, successors and assigns.',
                    "Acknowledgement Date: " + _this.props.formik.values.confirmDate
                ],
                CSP09: [
                    "I hereby release any and all claims, demands, actions, or causes of action, either known or unknown, against Mazda North American Operations and Mazda Motor Corporation, as well as all of their respective officers, directors, employees, subsidiaries, authorized dealers, predecessors, successors, heirs, and assigns (\"Released Parties\") arising out of or in any way related to the failure of the original equipment manufacturer (\"OEM\") clutch disc and any of the following OEM parts: (a) clutch cover/pressure plate; (b) clutch lever; (c) clutch fork; (d) clutch release collar/throwout bearing; (e) pivot pin clutch release fork; or (f) flywheel (\"Covered Clutch Assembly Parts\") within 60,000 miles and 60-months from the vehicle's in-service date in 2010-2013 Mazda3 vehicles equipped with a 2.5L DOHC I-4 Engine. This Release does not apply to any claims I may have for personal injury, wrongful death, or damage to other property as a result of the failure of Covered Clutch Assembly Parts within 60,000 miles and 60-months from the vehicle's in-service date.",
                    "I acknowledge that there may be information or facts that are different from, in addition to, and/or contrary to those that I now know or understand to be true, and that there may be damages, losses, costs, and expenses of which I am not currently aware or which are unanticipated at this time. By signing this Release, I acknowledge that I forever waive and discharge any rights which I may have against the Released Parties for any such claim which may arise in the future and that this Release shall be and remain effective in all respects, notwithstanding any such additional, and/or contrary information or facts.",
                    "I hereby waive any and all rights under California Civil Code Section 1542 notwithstanding any provision to the contrary. Section 1542 provides as follows:",
                    'A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.',
                    _this.props.vin,
                    "Acknowledgement Date: " + _this.props.formik.values.confirmDate
                ],
                CSP05: [
                    "Due to the termination of the 3G network by multiple carriers, our mobile-app-based remote engine start system will cease to function after September 30, 2021. We regret any inconvenience that this might cause you and want to help make any transition as smooth as possible. Below you will find the terms and conditions to receive the $400 CSP05 Customer Service Campaign goodwill offer.",
                    "The vehicle identification number (VIN) is: " + _this.props.vin,
                    "Terms and Conditions.",
                    "If you meet all of the requirements below, agree to all of the terms and conditions, and your application is approved, you will receive a non-negotiable goodwill offer of $400 Dollars in the form of a check or reward card. Please allow 4-6 weeks for processing of your application beginning June 15, 2021.",
                    "1. Requirements",
                    "a. You own or have owned a Mazda vehicle identified as part of the CSP05 Customer Service Campaign, and you have taken your vehicle to an authorized Mazda dealership to have the old 3G based Mazda Mobile Start removed",
                    "b. You have not chosen any other option under the CSP05 Customer Service Campaign.",
                    "c. You have properly uploaded all required documentation:",
                    "i. Vehicle model and year, and vehicle identification number (VIN)",
                    "ii. Your name and address at the time of repair",
                    "iii. Work completed by a Mazda dealer to remove the old 3G-based Mazda Mobile Start system",
                    "2. Once the application is processed and the $400 goodwill offer approved, you will not be able to choose any other option under the CSP05 Customer Service Campaign. If Mazda has records that another option was chosen under the CSP05 Customer Service Campaign, your application will be denied and no $400 goodwill offer will be provided.",
                    "3. Any submission that is incomplete or submitted without all of the proper documentation will be rejected. If Mazda has any questions concerning your application, you may be contacted.",
                    "4. By submitting this claim, I confirm that I have read and agreed to all of the terms and conditions related to the CSP05 Customer Service Campaign. In exchange for Mazda's goodwill offer of $400, I release Mazda North American Operations, its authorized dealerships, and Mazda Motor Corporation from all claims related to the CSP05 Customer Service Campaign.",
                    "Acknowledgement Date: " + _this.props.formik.values.confirmDate
                ]
            };
            return templates[disclaimerTemplateName];
        };
        _this.disclaimerMarkup = {
            default: (React.createElement("div", null,
                React.createElement("div", null, _this.disclaimerTemplate('default')[0]),
                React.createElement("div", null, _this.disclaimerTemplate('default')[1]),
                React.createElement("div", null, _this.disclaimerTemplate('default')[2]),
                React.createElement(FormField, { name: "confirmDate", label: "Date", type: "date", "data-test": "confirm_date", autoFocus: true, validate: _this.confirmDateValidation }))),
            CSP09: (React.createElement("div", null,
                React.createElement("div", null, _this.disclaimerTemplate('CSP09')[0]),
                React.createElement("div", null, _this.disclaimerTemplate('CSP09')[1]),
                React.createElement("div", null, _this.disclaimerTemplate('CSP09')[2]),
                React.createElement("div", null, _this.disclaimerTemplate('CSP09')[3]),
                React.createElement("div", null, _this.disclaimerTemplate('CSP09')[4]),
                React.createElement(FormField, { name: "confirmDate", label: "Date", type: "date", "data-test": "confirm_date", autoFocus: true, validate: _this.confirmDateValidation }))),
            CSP05: (React.createElement("div", null,
                React.createElement("div", null, _this.disclaimerTemplate('CSP05')[0]),
                React.createElement("div", null, _this.disclaimerTemplate('CSP05')[1]),
                React.createElement("div", { className: styles.boldAndUnderline }, _this.disclaimerTemplate('CSP05')[2]),
                React.createElement("div", null, _this.disclaimerTemplate('CSP05')[3]),
                React.createElement("div", null,
                    React.createElement("p", null, _this.disclaimerTemplate('CSP05')[4]),
                    React.createElement("ul", { className: styles.removeListStyle },
                        React.createElement("li", null, _this.disclaimerTemplate('CSP05')[5]),
                        React.createElement("li", null, _this.disclaimerTemplate('CSP05')[6]),
                        React.createElement("li", null, _this.disclaimerTemplate('CSP05')[7]),
                        React.createElement("ul", { className: styles.removeListStyle },
                            React.createElement("li", null, _this.disclaimerTemplate('CSP05')[8]),
                            React.createElement("li", null, _this.disclaimerTemplate('CSP05')[9]),
                            React.createElement("li", null, _this.disclaimerTemplate('CSP05')[10])))),
                React.createElement("div", null, _this.disclaimerTemplate('CSP05')[11]),
                React.createElement("div", null, _this.disclaimerTemplate('CSP05')[12]),
                React.createElement("div", null, _this.disclaimerTemplate('CSP05')[13]),
                React.createElement(FormField, { name: "confirmDate", label: "Date", type: "date", "data-test": "confirm_date", autoFocus: true, validate: _this.confirmDateValidation })))
        };
        _this.createDisclaimerFile = function () { return __awaiter(_this, void 0, void 0, function () {
            var disclaimerTemplateName, disclaimerTemplate, response, disclaimerFile;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        disclaimerTemplateName = this.state.disclaimerTemplateName;
                        disclaimerTemplate = this.disclaimerTemplate(disclaimerTemplateName);
                        return [4 /*yield*/, axios.post('/api/create-disclaimer', { disclaimerTemplateName: disclaimerTemplateName, disclaimerTemplate: disclaimerTemplate }, { responseType: 'blob' })];
                    case 1:
                        response = _a.sent();
                        disclaimerFile = new File([response.data], DISCLAIMER_FILENAME, { type: DISCLAIMER_MIMETYPE });
                        return [2 /*return*/, disclaimerFile];
                }
            });
        }); };
        _this.setDisclaimerExists = function (disclaimerFile) { return __awaiter(_this, void 0, void 0, function () {
            var type, lastModified, name, size;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        type = disclaimerFile.type, lastModified = disclaimerFile.lastModified, name = disclaimerFile.name, size = disclaimerFile.size;
                        if (!(disclaimerFile && type && lastModified && name === DISCLAIMER_FILENAME && size >= MIN_FILE_SIZE_BYTE)) return [3 /*break*/, 2];
                        this.props.formik.setFieldValue('files', __spread([disclaimerFile], this.props.formik.values.files), false);
                        return [4 /*yield*/, this.setState({ disclaimerExists: true })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.setState({ disclaimerExists: false })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.submit = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var disclaimerFile;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        if (!!this.state.disclaimerExists) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.createDisclaimerFile()];
                    case 1:
                        disclaimerFile = _a.sent();
                        return [4 /*yield*/, this.setDisclaimerExists(disclaimerFile)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this.props.updateProgress();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    DisclaimerForm.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var campaignId, response, disclaimerTemplateName;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        campaignId = this.props.formik.values.campaignId;
                        return [4 /*yield*/, axios.get("/api/template-name?projectId=" + campaignId)];
                    case 1:
                        response = _a.sent();
                        disclaimerTemplateName = response.data.disclaimerTemplate;
                        return [4 /*yield*/, this.setState({ disclaimerTemplateName: disclaimerTemplateName })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ;
    DisclaimerForm.prototype.render = function () {
        var _this = this;
        return (React.createElement("section", { className: styles.disclaimerForm, "data-test": "disclaimer_submissions_form" },
            React.createElement(PreventTransitionPrompt, { when: true, title: "Exiting this screen will delete information entered", message: "Do you want to exit this screen?", redirectPath: "/" }),
            React.createElement("fieldset", { className: styles.inputGroup },
                React.createElement("h3", null, "Disclaimer"),
                this.disclaimerMarkup[this.state.disclaimerTemplateName],
                React.createElement(Checkbox, { checked: this.state.acknowledged, dataTest: "acknowledgement", action: function () { return _this.setState({ acknowledged: !_this.state.acknowledged }); }, text: "I am aware and agree to the General Release for this Mazda Customer Reimbursement" })),
            React.createElement(Button, { variant: "primary", type: "submit", disabled: this.disableSubmit(), onClick: this.submit, "data-test": "submit" }, "Submit")));
    };
    return DisclaimerForm;
}(React.Component));
export default connect(DisclaimerForm);
